import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
// import Image from "gatsby-image"
import TopMenu from "../components/topmenu"
// import {chunkifyArray} from "../utils/utils"
import Footer from "../components/footer"
import PhotoGallery from "../components/photoGallery"
import frame from "../../content/assets/frame.svg"

const AlbumPageTemplate = (props) => {
    // console.log("AlbumPageTemplate: ", props);
    // const { previous, next } = props.pageContext;
    const slug = props.data.mdx.slug;
    const fileNameRegExp = /\/([^/]+)\.\w{1,4}$/;
    const album = props.data.mdx;
    const cloudinaryImages = props.data.cloudinaryImages.nodes;

    let pictures = album.frontmatter.pictures.map((pic, index) => {
      const res = fileNameRegExp.exec(pic.image);
      if (!res || !res[1]) return undefined;
      const picData = cloudinaryImages.filter(pic => pic.public_id.includes(res[1]))[0];
      return {
        index: index,
        type: picData.type || "image",
        id: picData.id,
        src: picData.imgUrl,
        thumbnail: picData.thumb,
        w: picData.width,
        h: picData.height,
        title: picData.title || "",
        description: picData.description || "",
      };
    });

    pictures = [
      {
        type: "text",
        title: album.frontmatter.title,
        description: album.frontmatter.description,
      }, 
      ...pictures
    ];
    // console.log(pictures);

    // const columns = chunkifyArray(pictures, 3, true);

    const getThumbnailContent = (item) => {
      return (
        <div className="">
          <img src={item.thumbnail} alt={item.title} loading="lazy" className="tar-album-item__pic"/>
          <img src={frame} alt="frame" className="tar-album-item__frame"/>
        </div>
      );
    }

    return (
      <section className="tar-page tar-album">
        <SEO
          title={album.frontmatter.title}
          description={album.frontmatter.description}
          keywords={[`photo`, `photographer`, `Taranukhina`, `Elena`,`valokuvaaja`,`Tampere`]}
        />
     <TopMenu smallLogo={true} lang={props.pageContext.language} languages={props.pageContext.languages} translation={props.pageContext.translation} slug={slug}></TopMenu>
     <div className="container-1200">
       <div className="tar-section">
       <PhotoGallery items={pictures} options={{}} thumbnailContent={getThumbnailContent}/>
       </div>
       <div className="tar-section-buttons mb60 tac">
        <Link to={props.pageContext.translation.nav[1].url} className="btn">
            <button className="tar-button">
              {props.pageContext.translation.nav[1].title}
            </button>
          </Link>
       </div>
     </div>
   <Footer lang={props.pageContext.language} languages={props.pageContext.languages} translation={props.pageContext.translation}></Footer>
  
  
      </section>
    )
  }

export default AlbumPageTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx( slug: { eq: $slug }) {
      id
      slug
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        description
        title
        pictures {
          image
        }
      }
    }
    cloudinaryImages: allCloudinaryItem {
      nodes {
        id
        public_id
        thumb
        imgUrl
        width
        height
        orientation
      }
    }
  }
`
