import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from "react-markdown"
import classnames from 'classnames';
import pick from 'lodash.pick';
import { PhotoSwipe } from 'react-photoswipe-2';
import events from 'react-photoswipe-2/src/events.js';
import {chunkifyArray} from "../utils/utils"


class PhotoGallery extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    options: PropTypes.object,
    thumbnailContent: PropTypes.func,
    id: PropTypes.string,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
  };

  static defaultProps = {
    options: {},
    thumbnailContent: item => (
      <img src={item.src} width="100" height="100" loading="lazy" alt=""/>
    ),
    id: '',
    className: '',
    isOpen: false,
    onClose: () => {
    }
  };

  state = {
    isOpen: this.props.isOpen,
    options: this.props.options
  };

  componentWillReceiveProps = (nextProps) => {
    const { isOpen } = this.state;
    if (nextProps.isOpen) {
      if (!isOpen) {
        this.setState({ isOpen: true });
      }
    } else if (isOpen) {
      this.setState({ isOpen: false });
    }
  };

  showPhotoSwipe = itemIndex => (e) => {
    e.preventDefault();
    const getThumbBoundsFn = ((index) => {
      const thumbnail = this.thumbnails[index];
      const img = thumbnail.getElementsByTagName('img')[0];
      const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
      const rect = img.getBoundingClientRect();
      return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
    });
    const { options } = this.state;
    options.index = itemIndex;
    options.getThumbBoundsFn = options.getThumbBoundsFn || getThumbBoundsFn;
    this.setState({
      isOpen: true,
      options
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false
    });
    this.props.onClose();
  };

  render() {
    const { id, items, thumbnailContent, ...other } = this.props;
    const columns = chunkifyArray(items, 3, true);
    const pictures = items.filter(item => item.type !== "text");
    // console.log(pictures);

    let { className } = this.props;
    className = classnames(['pswp-gallery', className]).trim();
    const eventProps = pick(other, events);
    const { isOpen, options } = this.state;
    return (
      <div id={id} className={className}>
          <div className="tar-album-columns">
          {
             columns.map((col, colIndex)=>{
               return (
            <div key={colIndex} className="tar-album-column">

                {/* <div className="pswp-thumbnails"> */}
                {col.map((item, index) => {
                    if(item.type === 'text'){
                        return (
                          <div key={index} className="tar-album-item tar-album-item--description">
                              <h3 className="tar-albums-in-title">{item.title}</h3>
                              <div className="tar-title-line"></div>
                              <div className="tar-albums-in-text tar-article ffrw">
                                <ReactMarkdown source={item.description} linkTarget="_blank"/>
                              </div>
                        </div>  
                        )
                      } 
                      else return (
                        <button
                        key={item.id}
                        ref={(node) => {
                            this.thumbnails = this.thumbnails || [];
                            this.thumbnails[item.index] = node;
                        }}
                        className="pswp-thumbnail tar-album-item"
                        onClick={this.showPhotoSwipe(item.index)}
                        >
                        {thumbnailContent(item)}
                        </button>
                      )
             })}
                {/* </div> */}
            </div>
            )})}
        </div>
        <PhotoSwipe
          {...eventProps}
          isOpen={isOpen}
          items={pictures}
          options={options}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}

export default PhotoGallery;